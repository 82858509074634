<template>
  <!--会员充值规则 -->
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent">
    </TablePage>
  </div>
</template>

<script>
import TablePage from "@/components/tablePage"; //表格页面组件
import {
  leadMaterialListAPI,
  leadMaterialUpdateStatusAPI,
  listImportBillInfo
} from "@/api/produce/bill/leadMaterial"; //生产领料
export default {
  name: "LeadMaterial",
  components: { TablePage },
  dicts: ["bill_status"],
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      //从首页待办业务进来
      if (to.query.billStatus && from.fullPath == "/index") {
        vm.options.defaultBody = { billStatuss: [to.query.billStatus] };
        vm.options.search[3].model = [to.query.billStatus];
        vm.options.search[1].defaultValue = "";
        let cloneData = cloneDeep(vm.options);
        vm.options = null;
        vm.options = cloneData;
      } else {
        vm.thisMonth = "thisMonth";
      }
    });
  },
  data() {
    return {
      options: {
        mutiSelect: true, // 多选
        loading: false,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        getListApi: leadMaterialListAPI,
        listNo: true, // 序号
        check: [], // 选中数据
        rowKey: "billId",
        //搜索
        search: [
          {
            label: "单据日期",
            type: "daterange",
            model: "",
            noReset: true,
            filterFnc: ([a, b] = []) => ({ beginBillDate: a + ' 00:00:00', endBillDate: b + ' 23:59:59' }),
          },
          {
            keyIndex: 0,
            format: "daterange",
            type: "timeRange",
            defaultValue: "month",
            data: [
              "day",
              "yesterday",
              "week",
              "lastWeek",
              "month",
              "lastMonth",
            ],
          },
          {
            type: "input",
            label: "单据编号",
            model: "",
            filter: "billNo",
            seniorSearch: true,
          },
          this.$select({
            key: "bill_status",
            option: {
              seniorSearch: true,
              option: {
                multiple: true,
              },
            },
          }),
          this.$select({
            key: "listProduceDept",
            option: {
              type: "remote",
              label: "领料工厂",
              filter: "deptIds",
              seniorSearch: true,
              option: {
                multiple: true,
              },
            },
          }),
          this.$select({
            key: "listStore",
            option: {
              type: "remote",
              seniorSearch: true,
              label: "领料仓库",
              filter: "outStoreIds",
              option: {
                multiple: true,
              },
            },
          }),
          this.$select({
            key: "listEmployee",
            option: {
              type: "remote",
              seniorSearch: true,
              label: "创建人",
              filter: "createEmployeeIds",
              option: {
                multiple: true,
              },
            },
          }),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        //按钮
        buttons: [
          {
            click: "add",
            label: "新增",
            icon: "el-icon-plus",
            type: "primary",
          },
          {
            click: "audit",
            label: "审核",
            alertText: "确认要审核选中方案吗？",
            btnType: "dropdown",
            type: "success",
            other: [
              {
                click: "reverseAudit",
                label: "反审核",
                alertText: "确认要反审核选中方案吗？",
              },
            ],
          },
          {
            label: "打印",
            type: "primary",
            btnType: "dropdown",
            print: {},
          },
          {
            click: "del",
            label: "删除",
            type: "danger",
            icon: "el-icon-delete",
          },
          {
            click: "getRelevantDocuments",
            label: "相关单据",
            type: "primary",
          },
          {
            click: "refresh",
            right: true,
            label: "刷新",
            icon: "el-icon-refresh",
            type: "",
          },
        ],
        //表格
        columns: [
          {
            prop: "billNo",
            label: "单据编号",
            type: "link",
            click: "update",
            minWidth: 150,
            align: "center",
          },
          {
            prop: "billDate",
            label: "单据日期",
            minWidth: 155,
            align: "center",
          },
          {
            prop: "billStatusName",
            label: "单据状态",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "sourceBillNo",
            label: "生产计划单号",
            minWidth: 160,
            align: "center",
          },
          {
            prop: "produceDeptName",
            label: "领料工厂",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "deptName",
            label: "部门",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "outStoreName",
            label: "领料仓库",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "billRemark",
            label: "备注",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "auditBy",
            label: "审核人",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "auditTime",
            label: "审核时间",
            minWidth: 155,
            align: "center",
          },
          {
            prop: "createBy",
            label: "创建人",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "createTime",
            label: "创建日期",
            minWidth: 155,
            align: "center",
          },
          {
            prop: "updateBy",
            label: "修改人",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "updateTime",
            label: "修改日期",
            minWidth: 155,
            align: "center",
          },
        ],
        list: [],
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      let billIds = [];
      switch (type) {
        case "add":
          this.$router.push({
            name: "leadMaterialDetail",
          });
          break;
        case "update":
          this.$router.push({
            name: "leadMaterialDetail",
            query: {
              billId: row.billId,
              type: "Update",
            },
          });
          break;
        case "audit":
          this.options.check.forEach((item) => {
            if (item.billStatus == "0") billIds.push(item.billId);
          });
          if (billIds.length >= 1) {
            try {
              await leadMaterialUpdateStatusAPI({
                billIds,
                billType: "170202",
                billStatus: 2,
              });
              this.$message.success("审核成功");
              this.$refs.tablePage.getList();
            } catch (err) { }
          } else {
            this.$message.error("请选择未审核的单据");
          }
          break;
        case "reverseAudit":
          this.options.check.forEach((item) => {
            if (item.billStatus == "2") billIds.push(item.billId);
          });
          if (billIds.length >= 1) {
            try {
              await leadMaterialUpdateStatusAPI({
                billIds,
                billType: "170202",
                billStatus: 0,
              });
              this.$message.success("反审核成功");
              this.$refs.tablePage.getList();
            } catch (err) { }
          } else {
            this.$message.error("请选择已审核的单据");
          }
          break;
        case "del":
          this.options.check.forEach((item) => {
            if (item.billStatus == "0") billIds.push(item.billId);
          });
          if (billIds.length >= 1) {
            try {
              await leadMaterialUpdateStatusAPI({
                billIds,
                billType: "170202",
                billStatus: 3,
              });
              this.$message.success("删除成功");
              this.$refs.tablePage.getList();
            } catch (err) { }
          } else {
            this.$message.error("请选择未审核的单据");
          }
          break;
        case "getRelevantDocuments":
          if (this.options.check.length != 1) {
            return this.$message.error('请勾选一条数据查询相关单据！')
          }
          const res = await listImportBillInfo(this.options.check[0].billId)
          let data = res.data || []
          this.$router.push({
            name: 'produceLeadMaterialRelevant',
            query: {
              type: 'produceLeadMaterial',
              data: JSON.stringify(data)
            }
          })
          break;

        default:
          break;
      }
    },
  },
};
</script>
